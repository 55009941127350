import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import Header from 'components/header'

export default () => (
  <Layout>
    <Helmet>
      <title>
        Cookie policy | Helios Underwriting
      </title>
    </Helmet>
    <main>
      <Header layout="standard" />
      <section className="padding-md">
        <h1 className="h1 lg:u-2/3">
          Cookie policy
        </h1>
      </section>

      <section className="padding-md lg:u-2/3">
        <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
        <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
        <p>We use the following cookies:</p>
        <ul class="o-list my-20">
          <li><div><span class="u-bold">Strictly necessary cookies.</span> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</div></li>
          <li><div><span class="u-bold">Analytical or performance cookies.</span> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</div></li>
          <li><div><span class="u-bold">Functionality cookies.</span> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</div></li>
          <li><div><span class="u-bold">Targeting cookies.</span> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website more relevant to your interests.</div></li>
        </ul>
        <p>We do not share the information collected by the cookies with any third parties.</p>
        <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>
      </section>

    </main>
  </Layout>
)
